<template>
    <div>
        <fa-icon :icon="['fas', 'square-plus']" size="lg" style=" padding-left:10px; cursor: pointer;"
                 @click="addFlight(value)" ref="addFlight"/>
        <fids_form v-if="open && value" v-model="value" @sendObject="sendData" :modalId="createId" :flightTitle="'Create Flight'"></fids_form>
    </div>
</template>

<script>
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import fcr from "@/services/fcr";
import blackhole from "@/services/blackhole";
import fids_form from "@/views/expansion_modules/fids/fids_form";
import Vue from "vue";

export default {
    name: "fids_create",
    props: {
        value: Object,
    },
    data() {
        return {
            logoId: '',
            options: {
                format: 'MM/DD/YYYY h:mm a',
                useCurrent: true,
            },
            response: "",
            createId: "createId",
            open: false
        }
    },
    components: {
        fids_form,
        datePicker,
    },
    methods: {
        sendData(result) {
            let temp = {rollIn: new Date(result.rollIn).getTime(), rollOut: new Date(result.rollOut).getTime()};

            fcr.createFlight({...result, ...temp}).then(() => {
                this.$emit("reloadList");
            }, error => {
                console.error(error);
            });
        },
        addFlight(data) {
            this.open = true;
            this.value = data;
            Vue.nextTick().then(() => {
                this.$root.$emit("bv::toggle::modal", "createId", "#fids_manage");
            });

        },
    },

    computed: {
        logoPreview: function () {
            if (!this.logoId) {
                return "";
            } else {
                return blackhole.readImage(this.logoId);
            }
        }
    }
}
</script>

<style scoped>
h5 {
    margin-bottom: 0;
}
</style>