<template>
    <div>
        <fa-icon :icon="['fas', 'pen-to-square']" size="lg" style=" padding-left:10px; cursor: pointer;"
                 @click="openUpdateFlight(value)" ref="updateFlight"/>
        <fids_form v-if="open && value" v-model="updateInfo" @sendObject="sendData" :modalId="value._id" :flightTitle="'Update Flight'"></fids_form>
    </div>
</template>

<script>
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import fcr from "@/services/fcr";
import blackhole from "@/services/blackhole";
import fids_form from "@/views/expansion_modules/fids/fids_form";
import Vue from "vue";

export default {
    name: "fids_update",
    props: {
        id: Object,
        value: Object
    },
    data() {
        return {
            logoId: '',
            airlines: [],
            flights: [],
            response: "",
            options: {
                format: 'MM/DD/YYYY h:mm a',
                useCurrent: true,
            },
            input: {
                type: "",
            },
            open: false,
            updateInfo: {},
        }
    },
    components: {
        datePicker,
        fids_form
    },
    created() {
        this.updateInfo = Object.assign({}, this.value);
    },
    methods: {
        sendData(updatedInfo) {
            let temp = {rollIn: new Date(updatedInfo.rollIn).getTime(), rollOut: new Date(updatedInfo.rollOut).getTime()};

            fcr.updateFlight(updatedInfo._id, {...updatedInfo, ...temp}).then(result => {
                this.response = result.data;
                this.$emit("reloadList");
            }, error => {
                console.error(error);
            });
        },
        openUpdateFlight(data) {
            this.open = true;
            Vue.nextTick().then(() => {
                this.$root.$emit("bv::toggle::modal", this.value._id, "#fids_manage");
            });
        },
    },
    computed: {
        logoPreview: function () {
            if (!this.logoId) {
                return "";
            } else {
                return blackhole.readImage(this.logoId);
            }
        }
    },
}
</script>

<style scoped>

</style>