<template>
    <div class="wrapper">
        <b-modal no-close-on-backdrop :title="'Confirm Delete'" class="modal-dark" v-model="deleteModal"
                 ok-variant="danger">
            <p>If you would like to delete this flight please type the name below.</p>
            <br>
            <p>Enter "{{ currentFlight.flight }}":</p>
            <b-form-group description="case sensitive">
                <b-form-input v-model="nameVerification" type="text"></b-form-input>
            </b-form-group>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="deleteModal=false">Cancel</b-button>
                <b-button :disabled="nameVerification !== currentFlight.flight" size="sm" variant="danger"
                          @click="removeData(currentFlight._id)">Delete
                </b-button>
            </template>
        </b-modal>

        <span style="display: inline-block; white-space: nowrap; justify-content: flex-end" class="d-flex w-100">
            <fids_create v-model="addData" ref="fids_manage" @reloadList="updateFlightList"></fids_create>
            <router-link class="non-link " :to="{ name: 'Fids Airlines'}">
                <i style=" padding-left:10px;" class="icon-plane icons font-xl"></i>
            </router-link>
        </span>

        <b-row>
            <b-col>
                <div v-for="(flight, index) in flights" :key="flight._id">
                    <b-card header-tag="header" footer-tag="footer" header="Card outline primary"
                            :border-variant="flight.boarder">
                        <div slot="header">
                            <span class="d-flex w-100 justify-content-between">
                                <img :src="mapAirlineLogo(flight.airline)" style="height:50px;" alt="">
                                <span style="display: flex; white-space: nowrap;">
                                    <fids_update v-model="flights[index]" @reloadList="updateFlightList"/>
                                    <fa-icon :icon="['fas', 'trash-can']" size="lg"
                                             style="padding-top: 3px; padding-left:10px; cursor: pointer;" @click="showDeleteModal(flight)"/>
                                </span>
                            </span>
                        </div>
                        <div class="row" v-if="windowWidth() < 1200">
                            <div class="col-md-6">
                                <h3>Flight: <b>{{ flight.flight }}</b></h3>
                                <h3>Gate: <b>{{ flight.gate }}</b></h3>
                                <h3>Status: <b>{{ flight.status }}</b></h3>
                                <h3>Departing: <b>{{ flight.departing }}</b></h3>
                                <h3>Arriving: <b>{{ flight.arriving }}</b></h3>
                                <h3>Time: <b>{{ flight.departuretime }}</b></h3>
                                <h3>Roll In: <b>{{ flight.rollIn }}</b></h3>
                                <h3>Roll Out: <b>{{ flight.rollOut }}</b></h3>
                            </div>
                        </div>
                        <div v-else class="text-center">
                            <div class="row">
                                <div class="col">
                                    <h3>Flight: <b>{{ flight.flight }}</b></h3>
                                </div>
                                <div class="col">
                                    <h3>Gate: <b>{{ flight.gate }}</b></h3>
                                </div>
                                <div class="col">
                                    <h3>Status: <b>{{ flight.status }}</b></h3>
                                </div>
                                <div class="col">
                                    <h3>Departing: <b>{{ flight.departing }}</b></h3>
                                    <h3>Departure Time: <b>{{ flight.departuretime }}</b></h3>
                                </div>
                                <div class="col">
                                    <h3>Arriving: <b>{{ flight.arriving }}</b></h3>
                                    <h3>Arrival Time: <b>{{ flight.arrivaltime }}</b></h3>
                                </div>
                                <div class="col">
                                    <h3>Roll In: <b>{{ flight.rollIn }}</b></h3>
                                </div>
                                <div class="col">
                                    <h3>Roll Out: <b>{{ flight.rollOut }}</b></h3>
                                </div>
                            </div>
                        </div>
                    </b-card>
                </div>

            </b-col>
        </b-row>
    </div>
</template>

<script>
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import * as moment from 'moment';
import fcr from "@/services/fcr";
import blackhole from "@/services/blackhole";
import fids_create from "@/views/expansion_modules/fids/fids_create";
import fids_update from "@/views/expansion_modules/fids/fids_update";

export default {
    name: "fids_overview",
    components: {
        fids_update,
        fids_create,
    },
    data() {
        return {
            flights: [],
            airlines: [],
            currentFlight: {},
            deleteModal: false,
            nameVerification: '',
            addData: {
                boarder: "info",
                airline: "",
                flight: "",
                gate: "",
                status: "",
                departing: "",
                arriving: "",
                departuretime: "",
                arrivaltime: "",
                rollIn: "",
                rollOut: "",
                type: "",
                baggageclaimnumber: "",
            },
        }
    },
    created() {
        fcr.readAirlines().then(response => {
            this.airlines = response.data;
        }, error => {
            console.error(error);
        });

        fcr.readFlights().then(response => {
            response.data.forEach(flight => {
                flight.rollIn = moment.unix(parseInt(flight.rollIn) / 1000).format('MM/DD/YYYY h:mm:ss a');
                flight.rollOut = moment.unix(parseInt(flight.rollOut) / 1000).format('MM/DD/YYYY h:mm:ss a');
            });
            this.flights = response.data;
        }, error => {
            console.error(error);
        });
    },
    methods: {
        showDeleteModal(flight) {
            this.currentFlight = flight;
            this.nameVerification = "";
            this.deleteModal = true;
        },
        mapAirlineLogo(airlineName) {
            for (let airline of this.airlines) {
                if (airline.name === airlineName) {
                    return this.logo(airline.image);
                }
            }
        },
        logo(logoId) {
            if (!logoId) {
                return ""
            } else {
                return blackhole.readImage(logoId);
            }
        },
        windowWidth() {
            return window.innerWidth;
        },
        removeData(id) {
            this.deleteModal = false;
            fcr.deleteFlight(id).then(result => {
                fcr.readFlights().then(response => {
                    response.data.forEach(flight => {
                        flight.rollIn = moment.unix(parseInt(flight.rollIn) / 1000).format('MM/DD/YYYY h:mm:ss a');
                        flight.rollOut = moment.unix(parseInt(flight.rollOut) / 1000).format('MM/DD/YYYY h:mm:ss a');
                    });
                    this.flights = response.data;
                }, error => {
                    console.error(error);
                });
            }, error => {
                console.error(error);
            });
        },
        updateFlightList() {
            fcr.readFlights().then(response => {
                response.data.forEach(flight => {
                    flight.rollIn = moment.unix(parseInt(flight.rollIn) / 1000).format('MM/DD/YYYY h:mm:ss a');
                    flight.rollOut = moment.unix(parseInt(flight.rollOut) / 1000).format('MM/DD/YYYY h:mm:ss a');
                });
                this.flights = response.data;
            }, error => {
                console.error(error);
            });
        }
    }
}
</script>

<style scoped>
.button {
    width: 121px;
    margin-bottom: 10px;
}

</style>